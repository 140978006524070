var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-5"},[_c('v-col',[_c('div',{staticClass:"text-h5 font-weight-black"},[_vm._v("Reservaciones")])])],1),_c('v-data-table',{staticClass:"text-body-1",attrs:{"headers":_vm.headers,"items":_vm.reservationData,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-subtitle-1 mx-1 my-3",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-subtitle-1 mx-1 my-3",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.service)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-subtitle-1 mx-1 my-3",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-subtitle-1 mx-1 my-3",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.city)+" ")])]}},{key:"item.pay",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-subtitle-1 mx-1 my-3",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.pay)+" ")])]}},{key:"item.btn",fn:function(ref){
var item = ref.item;
return [_c('Button',{staticClass:"my-2",attrs:{"text":"Ver recibo","outlined":"","aria-label":"Descargar recibo"},on:{"event":function($event){return _vm.sendDetails(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }