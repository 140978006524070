<template>
  <v-container>
    <v-row no-gutters class="my-3">
      <v-col
        v-for="(service, i) in formatServices"
        :key="i"
        cols="11"
        sm="6"
        md="4"
        lg="3"
        class="pa-6 pa-sm-5 pa-md-5"
      >
        <div class="d-flex justify-start" max-width="270">
          <Card :card="service" type="ProviderServicesDetails" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Card from "@/components/Shared/Card.vue";
export default {
  components: {
    Card,
  },
  props: {
    services: { type: Array, required: true },
  },
  computed: {
    formatServices() {
      return this.services.map((service) => {
        return {
          ...service,
          name: service.serviceName,
        };
      });
    },
  },
};
</script>

<style lang="scss"></style>
