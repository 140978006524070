<template>
  <div>
    <v-row class="mb-5">
      <v-col>
        <div class="text-h5 font-weight-black">Reservaciones</div>
      </v-col>
    </v-row>
    <v-data-table
      class="text-body-1"
      :headers="headers"
      :items="reservationData"
      :hide-default-footer="true"
      disable-pagination
    >
      <template v-slot:[`item.name`]="{ item }">
        <p color="transparent" class="text-subtitle-1 mx-1 my-3">
          {{ item.name }}
        </p>
      </template>
      <template v-slot:[`item.service`]="{ item }">
        <p color="transparent" class="text-subtitle-1 mx-1 my-3">
          {{ item.service }}
        </p>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <p color="transparent" class="text-subtitle-1 mx-1 my-3">
          {{ item.date }}
        </p>
      </template>
      <template v-slot:[`item.city`]="{ item }">
        <p color="transparent" class="text-subtitle-1 mx-1 my-3">
          {{ item.city }}
        </p>
      </template>
      <template v-slot:[`item.pay`]="{ item }">
        <p color="transparent" class="text-subtitle-1 mx-1 my-3">
          {{ item.pay }}
        </p>
      </template>
      <template v-slot:[`item.btn`]="{ item }">
        <Button
          text="Ver recibo"
          outlined
          class="my-2"
          aria-label="Descargar recibo"
          @event="sendDetails(item)"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "DataList",
  components: {
    Button,
  },
  props: {
    headers: { type: Array, default: () => [] },
    reservationData: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  methods: {
    sendDetails(item) {
      this.$router.push({
        name: "ProviderServicesDetails",
        params: {
          id: item.serviceName.toLowerCase(),
          data: item,
        },
      });
    },
  },
};
</script>

<style></style>
