<template>
  <v-container>
    <v-row class="d-flex justify-space-between">
      <v-col>
        <div class="text-h5 font-weight-black">Información general</div>
      </v-col>
      <v-col class="d-flex justify-end">
        <Button text="Editar" outlined color="primary" aria-label="Editar" />
      </v-col>
    </v-row>
    <v-row class="mb-8">
      <v-col
        v-for="(data, index) in dataList"
        :key="index"
        class="d-flex flex-column"
        cols="12"
        xs="12"
        sm="12"
        md="5"
        lg="5"
        xl="5"
      >
        <div class="text-h6 font-weight-black">{{ data.title }}</div>
        <div class="text-h6">{{ data.text }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h5 font-weight-black">Descripción</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue nisl
          mauris, tincidunt nulla ipsum ultricies venenatis. Pellentesque leo
          nunc ac facilisi eu vel feugiat vitae. Nisl quis amet tempus auctor
          fermentum, urna auctor mattis. Tempus vitae venenatis, pellentesque
          suspendisse. Sagittis nulla leo erat massa arcu arcu faucibus sed.
          Ante hac mauris est sit duis volutpat dolor. Et ut quam quis vel augue
          in ac ac. Suspendisse viverra massa tempor augue integer tortor nisi.
          Diam sit viverra arcu sagittis neque nullam. Est felis viverra
          elementum tortor tortor fermentum amet, lectus aliquet. Praesent
          hendrerit sed amet tortor. Tempus id ultrices commodo tincidunt diam
          tellus. Diam suspendisse lacus sed molestie.
        </div>
      </v-col>
    </v-row>
    <DataList
      :headers="headersProfile"
      :reservation-data="reservationData"
      class="mb-8 mt-10"
    />
  </v-container>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import DataList from "@/components/Admin/ProviderView/DataList.vue";

export default {
  components: {
    Button,
    DataList,
  },
  data() {
    return {
      dataList: [
        { title: "Nombre legal", text: "María" },
        { title: "Apellidos", text: "Pérez Carrizales" },
        { title: "Nombre de preferencias", text: "Mary" },
        { title: "Correo", text: "correo@gmail.com" },
        { title: "Teléfono", text: "6141338761" },
        { title: "Dirección", text: "Av. Valle Escondido 5500-10" },
        { title: "Ciudad", text: "Chihuahua" },
        { title: "Código postal", text: "31115" },
      ],
      headersProfile: [
        {
          text: "Empresa",
          class: "text-h6 black--text font-weight-bold",
          value: "name",
        },
        {
          text: "Servicio",
          class: "text-h6 black--text font-weight-bold",
          value: "service",
        },
        {
          text: "Fecha evento",
          class: "text-h6 black--text font-weight-bold",
          value: "date",
        },
        {
          text: "Ciudad",
          class: "text-h6 black--text font-weight-bold",
          value: "city",
        },
        {
          text: "Monto pagado",
          class: "text-h6 black--text font-weight-bold",
          value: "pay",
        },
        {
          text: "",
          value: "btn",
        },
      ],
      reservationData: [
        {
          name: "Veronica Ramirez",
          service: "Banquetes CUU",
          date: "00/00/0000",
          city: "Chihuahua",
          pay: "$1,200",
        },
        {
          name: "Nombre",
          service: "Empresa",
          date: "00/00/0000",
          city: "Chihuahua",
          pay: "$1,200",
        },
        {
          name: "Nombre",
          service: "Empresa",
          date: "00/00/0000",
          city: "Chihuahua",
          pay: "$1,200",
        },
      ],
    };
  },
};
</script>

<style></style>
