<template>
  <div>
    <v-data-table
      class="text-body-1"
      :headers="headers"
      :items="dataList"
      :hide-default-footer="true"
      disable-pagination
    >
      <template v-slot:[`item.name`]="{ item }">
        <p color="transparent" class="mx-1 my-3 text-subtitle-1">
          {{ item.serviceName }}
        </p>
      </template>
      <template v-slot:[`item.category`]="{ item }">
        <p color="transparent" class="mx-1 my-3 text-subtitle-1">
          {{ item.servideCategory }}
        </p>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span color="transparent" class="mx-1 my-3 text-subtitle-1">
          {{ item.date }}
        </span>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <Button
          text="Ver detalles"
          outlined
          class="my-2"
          aria-label="Ver detalles"
          :url="getRoute(item).name"
          :url-params="getRoute(item).params"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "ServiceTable",
  components: {
    Button,
  },
  props: {
    dataList: { type: Array, required: true },
  },
  computed: {
    headers() {
      return [
        {},
        {
          text: "Nombre",
          value: "serviceName",
          class: "text-h6 black--text font-weight-bold",
        },
        {
          text: "Categoría",
          value: "servideCategory",
          class: "text-h6 black--text font-weight-bold",
        },
        {
          text: "Fecha de publicación",
          value: "date",
          class: "text-h6 black--text font-weight-bold",
        },
        { value: "details" },
      ];
    },
  },
  methods: {
    getRoute(item) {
      return {
        name: "ProviderServicesDetails",
        params: {
          id: item.serviceName.toLowerCase(),
        },
      };
    },
  },
};
</script>

<style scoped>
.v-data-table >>> td,
.tableFontChange {
  font-size: 18px !important;
}
</style>
