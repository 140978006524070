<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h5 font-weight-black">Información general</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(data, index) in dataList"
        :key="index"
        class="d-flex flex-column"
        cols="12"
        xs="12"
        sm="12"
        md="5"
        lg="5"
        xl="5"
      >
        <div class="text-h6 font-weight-black">{{ data.title }}</div>
        <div class="text-h6">{{ data.text }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h5 font-weight-black">Fotos</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs=" 12" xl="9" lg="9">
        <div class="imgsPreview">
          <div v-for="(img, i) in Imgs" :key="i" class="imageHolder">
            <img src="https://cdn.vuetifyjs.com/images/cards/cooking.png" />
          </div>
        </div>
      </v-col>
    </v-row>
    <DataList
      :headers="headersCompany"
      :reservation-data="reservationData"
      class="mb-8 mt-8"
    />
  </v-container>
</template>

<script>
import DataList from "@/components/Admin/ProviderView/DataList.vue";
export default {
  components: {
    DataList,
  },
  data() {
    return {
      Imgs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      dataList: [
        { title: "Nombre de empresa", text: "Castalia" },
        { title: "Correo", text: "correo@gmail.com" },
        { title: "Teléfono", text: "6141338761" },
        { title: "Dirección", text: "Montessori 125" },
        { title: "Colonia", text: "El Baluarte" },
        { title: "Estado", text: "Coahuila" },
        { title: "Ciudad", text: "Saltillo" },
        { title: "Código postal", text: "25279" },
        { title: "Identificación patrón", text: "ID123.pdf" },
      ],
      headersCompany: [
        {
          text: "Cliente",
          class: "text-h6 black--text font-weight-bold",
          value: "name",
        },
        {
          text: "Servicio",
          class: "text-h6 black--text font-weight-bold",
          value: "service",
        },
        {
          text: "Fecha evento",
          class: "text-h6 black--text font-weight-bold",
          value: "date",
        },
        {
          text: "Ciudad",
          class: "text-h6 black--text font-weight-bold",
          value: "city",
        },
        {
          text: "Monto pagado",
          class: "text-h6 black--text font-weight-bold",
          value: "pay",
        },
        {
          text: "",
          value: "btn",
        },
      ],
      reservationData: [
        {
          name: "Veronica Ramirez",
          service: "Banquetes CUU",
          date: "00/00/0000",
          city: "Chihuahua",
          pay: "$1,200",
        },
        {
          name: "Nombre",
          service: "Empresa",
          date: "00/00/0000",
          city: "Chihuahua",
          pay: "$1,200",
        },
        {
          name: "Nombre",
          service: "Empresa",
          date: "00/00/0000",
          city: "Chihuahua",
          pay: "$1,200",
        },
      ],
    };
  },
};
</script>

<style>
.imgsPreview .imageHolder {
  width: 120px;
  height: 120px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 5px 5px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imgsPreview .imageHolder .delete {
  position: absolute;
  top: 4px;
  right: 4px;
}
.imgsPreview .imageHolder .delete:hover,
.plus:hover,
.beforeUpload input:hover {
  cursor: pointer;
}
.imgsPreview .imageHolder .plus {
  font-size: 21pt;
  height: 30px;
  width: 30px;
  text-align: center;
  border: 1px dashed;
  line-height: 26px;
  position: absolute;
  right: -42px;
  bottom: 43%;
}
</style>
