<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>
    <v-container class="body-container">
      <v-row>
        <v-col>
          <Button
            text="Volver"
            outlined
            pill
            :height="44"
            prepend-icon="fa-caret-left"
            class="my-10"
            aria-label="Volver a lista de cuentas"
            url="AdminAccounts"
          />
        </v-col>
      </v-row>
      <div
        class="d-flex mb-4"
        :class="`d-flex ${
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? 'flex-column-reverse'
            : 'flex-row justify-space-between'
        } `"
      >
        <div
          :class="`d-flex ${
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? 'flex-column'
              : 'flex-row'
          } `"
        >
          <div
            :class="`d-flex ${
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? 'justify-center mb-6 mt-6'
                : 'mr-8'
            } `"
          >
            <v-avatar size="120">
              <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
            </v-avatar>
          </div>
          <div
            :class="`d-flex ${
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? 'justify-center mb-6 mt-6 flex-column'
                : 'flex-column'
            } `"
          >
            <div>
              <div class="text-h4 font-weight-black mb-2">
                Luisa Jaramillo - Luisa
              </div>
            </div>
            <div
              :class="`d-flex ${
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'flex-column'
                  : 'flex-row'
              } `"
            >
              <div class="text-h6 mr-4">
                Usuario Zaturna desde: Febrero 2021
              </div>
              <div class="text-h6 mr-4">Chihuahua, Chihuahua</div>
              <div class="text-h6 mr-4">4.9 (150 reseñas)</div>
            </div>
          </div>
        </div>
        <div
          :class="`d-flex ${
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? 'justify-center'
              : 'justify-end'
          } `"
        >
          <div class="text-h6">
            Estatus:
            <v-icon class="mr-2" color="success"> mdi-brightness-1 </v-icon>
            Activo
          </div>
        </div>
      </div>
      <v-row class="mt-4 mb-2">
        <v-col>
          <v-card class="elevation-0">
            <v-tabs v-model="filterResult" background-color="white" show-arrows>
              <v-tabs-slider color="secondary"> </v-tabs-slider>
              <v-tab v-for="state in tabsOptions" :key="state.key">
                {{ state.name }}
              </v-tab>
            </v-tabs>
            <v-divider color="secondary"></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <div>
        <div v-if="filterResult === 0">
          <Profile class="mb-5" />
        </div>
        <div v-else-if="filterResult === 1">
          <Company class="mb-5" />
        </div>
        <div v-else>
          <Service class="mb-5" />
        </div>
      </div>
    </v-container>
    <Footer class="mt-0" />
  </v-container>
</template>

<script>
import Navbar from "@/components/Admin/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import Company from "@/components/Admin/ProviderView/Company.vue";
import Profile from "@/components/Admin/ProviderView/Profile.vue";
import Service from "@/components/Admin/ProviderView/Service.vue";
import Footer from "@/components/Shared/Footer.vue";

export default {
  components: {
    Navbar,
    Button,
    Company,
    Profile,
    Service,
    Footer,
  },
  data() {
    return {
      filterResult: 0,
      tabsOptions: [
        { name: "Perfil", value: "active" },
        { name: "Empresa", value: "active" },
        { name: "Servicio", value: "active" },
      ],
    };
  },
};
</script>

<style>
.purple-gradient {
  background-color: rgba(98, 37, 130, 255);
}
</style>
