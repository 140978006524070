var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"text-body-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mx-1 my-3 text-subtitle-1",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.serviceName)+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mx-1 my-3 text-subtitle-1",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.servideCategory)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mx-1 my-3 text-subtitle-1",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('Button',{staticClass:"my-2",attrs:{"text":"Ver detalles","outlined":"","aria-label":"Ver detalles","url":_vm.getRoute(item).name,"url-params":_vm.getRoute(item).params}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }