var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","ma-0":"","pa-0":""}},[_c('div',{staticClass:"purple-gradient fill-height"},[_c('Navbar')],1),_c('v-container',{staticClass:"body-container"},[_c('v-row',[_c('v-col',[_c('Button',{staticClass:"my-10",attrs:{"text":"Volver","outlined":"","pill":"","height":44,"prepend-icon":"fa-caret-left","aria-label":"Volver a lista de cuentas","url":"AdminAccounts"}})],1)],1),_c('div',{staticClass:"d-flex mb-4",class:("d-flex " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
          ? 'flex-column-reverse'
          : 'flex-row justify-space-between') + " ")},[_c('div',{class:("d-flex " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
            ? 'flex-column'
            : 'flex-row') + " ")},[_c('div',{class:("d-flex " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
              ? 'justify-center mb-6 mt-6'
              : 'mr-8') + " ")},[_c('v-avatar',{attrs:{"size":"120"}},[_c('img',{attrs:{"src":"https://cdn.vuetifyjs.com/images/john.jpg","alt":"John"}})])],1),_c('div',{class:("d-flex " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
              ? 'justify-center mb-6 mt-6 flex-column'
              : 'flex-column') + " ")},[_c('div',[_c('div',{staticClass:"text-h4 font-weight-black mb-2"},[_vm._v(" Luisa Jaramillo - Luisa ")])]),_c('div',{class:("d-flex " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? 'flex-column'
                : 'flex-row') + " ")},[_c('div',{staticClass:"text-h6 mr-4"},[_vm._v(" Usuario Zaturna desde: Febrero 2021 ")]),_c('div',{staticClass:"text-h6 mr-4"},[_vm._v("Chihuahua, Chihuahua")]),_c('div',{staticClass:"text-h6 mr-4"},[_vm._v("4.9 (150 reseñas)")])])])]),_c('div',{class:("d-flex " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
            ? 'justify-center'
            : 'justify-end') + " ")},[_c('div',{staticClass:"text-h6"},[_vm._v(" Estatus: "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-brightness-1 ")]),_vm._v(" Activo ")],1)])]),_c('v-row',{staticClass:"mt-4 mb-2"},[_c('v-col',[_c('v-card',{staticClass:"elevation-0"},[_c('v-tabs',{attrs:{"background-color":"white","show-arrows":""},model:{value:(_vm.filterResult),callback:function ($$v) {_vm.filterResult=$$v},expression:"filterResult"}},[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_vm._l((_vm.tabsOptions),function(state){return _c('v-tab',{key:state.key},[_vm._v(" "+_vm._s(state.name)+" ")])})],2),_c('v-divider',{attrs:{"color":"secondary"}})],1)],1)],1),_c('div',[(_vm.filterResult === 0)?_c('div',[_c('Profile',{staticClass:"mb-5"})],1):(_vm.filterResult === 1)?_c('div',[_c('Company',{staticClass:"mb-5"})],1):_c('div',[_c('Service',{staticClass:"mb-5"})],1)])],1),_c('Footer',{staticClass:"mt-0"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }