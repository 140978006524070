<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn-toggle v-model="dataDistribution" mandatory>
          <v-btn>
            <v-icon color="secondary"> fas fa-list </v-icon>
          </v-btn>
          <v-btn>
            <v-icon color="secondary"> fas fa-th-large </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <div v-if="dataDistribution === 0">
      <ServiceTable :data-list="servicesList" />
    </div>
    <div v-else>
      <ServiceGrid :services="servicesList" />
    </div>
  </v-container>
</template>

<script>
import ServiceTable from "@/components/Admin/ProviderView/ServiceTable.vue";
import ServiceGrid from "@/components/Admin/ProviderView/ServiceGrid.vue";
export default {
  components: {
    ServiceTable,
    ServiceGrid,
  },
  data() {
    return {
      dataDistribution: 0,
      servicesList: [
        {
          id: 1,
          serviceName: "Pizza",
          servideCategory: "Comida",
          providerName: "Castalia",
          date: "00/00/0000",
          status: "active",
          rating: 3,
          reviews: 200,
          location: "Chihuahua, Chihuahua",
          price: 1,
          workWhitOrganizers: "no",
          tagsList: ["Fotos", "Hermoso", "Morderno", "Arte"],
          refundInfo: [
            { id: 0, days: 12, discount: 10 },
            { id: 3, days: 15, discount: 10 },
          ],
        },
        {
          id: 2,
          serviceName: "DJ",
          servideCategory: "Musica",
          providerName: "Castalia",
          date: "00/00/0000",
          status: "active",
          rating: 4.7,
          reviews: 200,
          location: "Chihuahua, Chihuahua",
          price: 1,
          workWhitOrganizers: "no",
          tagsList: [],
          refundInfo: [],
        },
        {
          id: 3,
          serviceName: "Tacos",
          servideCategory: "Comida",
          providerName: "Castalia",
          date: "00/00/0000",
          status: "active",
          rating: 4.7,
          reviews: 200,
          location: "Chihuahua, Chihuahua",
          price: 1,
          workWhitOrganizers: "no",
          tagsList: [],
          refundInfo: [],
        },
        {
          id: 4,
          serviceName: "Toys",
          servideCategory: "Salon de eventos",
          providerName: "Castalia",
          date: "00/00/0000",
          status: "disable",
          rating: 4.7,
          reviews: 200,
          location: "Chihuahua, Chihuahua",
          price: 1,
          workWhitOrganizers: "no",
          tagsList: [],
          refundInfo: [],
        },
        {
          id: 5,
          serviceName: "G&G",
          servideCategory: "Salon de eventos",
          providerName: "Castalia",
          date: "00/00/0000",
          status: "disable",
          rating: 4.7,
          reviews: 200,
          location: "Chihuahua, Chihuahua",
          price: 1,
          workWhitOrganizers: "no",
          tagsList: [],
          refundInfo: [],
        },
        /*
        Agregar status y date a los componentes de registrar servicio y preguntar sobre si location lo podemos cambair por city y state en provider card
        {
          serviceName: "",
          stateDelivery: "",
          cityDelivery: "",
          locationDeliverList: [],
          allCities: false,
          serviceCategory: "",
          payPlan: "",
          refundInfo: [],
          priceRange: 1,
          workWhitOrganizers: "no",
          minNumPersons: "",
          maxNumPersons: "",
          tagsList: [],
        },*/
      ],
    };
  },
};
</script>

<style></style>
